<script setup lang="ts">
const { data: userData } = useAuth();
const { t, locale } = useI18n();
const { width } = useWindowSize();
const mobileSize = computed(() => width.value < 600);
const showRegButtons = ref(false);
const localePath = useLocalePath();
const isMounted = useMounted();
onMounted(async () => {
  if (!userData.value) {
    await nextTick();
    showRegButtons.value = true;
  }
});
const promoBannerUrl = computed(() => {
  return `/img/banners/banner-review-${
    locale.value === "ru" ? "ru" : "en"
  }.png`;
});
const promoDialog = ref(false);
const carouselRef = ref<HTMLElement | null>(null);
const { lengthX, direction } = useSwipe(carouselRef, {
  passive: true,
  onSwipeEnd() {
    if (Math.abs(lengthX.value) < 25) {
      return;
    }
    (carouselRef.value as any)?.[
      direction.value === "left" ? "next" : "prev"
    ]();
  },
});
</script>

<template>
  <div>
    <ClientOnly>
      <template #fallback>
        <el-skeleton style="width: 100%" animated>
          <template #template>
            <el-skeleton-item
              variant="image"
              style="width: 100%; height: 240px"
            />
          </template>
        </el-skeleton>
      </template>
      <el-carousel
        :height="`${isMounted && mobileSize ? '230px' : '320px'}`"
        trigger="click"
        arrow="never"
        :interval="8000"
        autoplay
        ref="carouselRef"
      >
        <el-carousel-item>
          <ClientOnly>
            <el-dialog
              :append-to-body="true"
              v-model="promoDialog"
              :title="t('c-exchange-reqItem-faq-promotion')"
              :fullscreen="width < 850"
              width="800px"
            >
              <div class="font-size-4">
                <p>
                  {{ t("c-exchange-reqItem-solid-swap-top-service") }}
                  <b class="color-warning">300 USDT</b>
                  {{ t("c-exchange-reqItem-add-to-account-monthly") }}
                </p>
                <p>{{ t("c-exchange-reqItem-how-can-you-do-it") }}</p>
                <ul class="mb-5">
                  <li>
                    {{ t("c-exchange-reqItem-register-and-exchange") }}
                  </li>
                  <li>
                    {{ t("c-exchange-reqItem-leave-review-on") }}
                    <a
                      class="el-link el-link--primary is-underline"
                      href="https://bitcointalk.org/index.php?topic=5511282"
                      target="_blank"
                      >BitcoinTalk,</a
                    >
                    <a
                      class="el-link el-link--primary is-underline"
                      href="https://forum.bits.media/index.php?/topic/238474-solidswap%E2%9C%94%EF%B8%8F%D0%BE%D0%B1%D0%BC%D0%B5%D0%BD%D0%BD%D0%B8%D0%BA-%D0%BA%D1%80%D0%B8%D0%BF%D1%82%D0%BE%D0%B2%D0%B0%D0%BB%D1%8E%D1%82%E2%9C%94%EF%B8%8F/"
                      target="_blank"
                      >Bits.Media</a
                    >
                  </li>
                  <li>
                    {{ t("c-exchange-reqItem-send-email-screenshot") }}
                    <a
                      href="https://t.me/manager_solidswap"
                      target="_blank"
                      class="el-link el-link--primary is-underline"
                      >{{ t("c-exchange-reqItem-manager-telegram") }}</a
                    >
                    {{ t("c-exchange-reqItem-or-email-to") }}
                    <a
                      class="el-link el-link--primary is-underline"
                      href="mailto:info@solidswap.net"
                      target="_blank"
                      >info@solidswap.net</a
                    >
                    {{ t("c-exchange-reqItem-enter-giveaway") }}
                  </li>
                </ul>
                <p>{{ t("c-exchange-reqItem-promotion-period") }}</p>
                <p>
                  *{{ t("c-exchange-reqItem-winners-selected-randomly") }}
                  <a href="https://t.me/solidswap_ru" target="_blank"
                    >Solid Swap Telegram channel.</a
                  >
                </p>
                <p>**{{ t("c-exchange-reqItem-participate-again") }}</p>
              </div>
            </el-dialog>
          </ClientOnly>
          <el-image
            style="width: 100%"
            class="cursor-pointer"
            :src="promoBannerUrl"
            @click="promoDialog = true"
          ></el-image>
        </el-carousel-item>
        <el-carousel-item>
          <h2>{{ t("p-index-our-blog") }}</h2>
          <BlogWidget :limit="1" title-only image-width="130px" />
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <h1>{{ t("p-index-welcome-title") }}</h1>
            <ul class="ml-6">
              <li>{{ t("p-index-welcome-no-fees") }}</li>
              <li>{{ t("p-index-welcome-stable") }}</li>
              <li>{{ t("p-index-welcome-no-id") }}</li>
            </ul>
            <p>{{ t("p-index-welcome-message-2") }}</p>
            <ClientOnly>
              <Transition>
                <div v-if="showRegButtons">
                  <NuxtLink :to="localePath('/login')" class="mr-4">
                    <el-button
                      round
                      :size="mobileSize ? 'small' : 'large'"
                      type="primary"
                    >
                      {{ t("p-index-get-started") }}
                    </el-button>
                  </NuxtLink>
                  <NuxtLink :to="localePath('/about')">
                    <el-button round :size="mobileSize ? 'small' : 'large'">
                      {{ t("p-index-learn-more") }}
                    </el-button>
                  </NuxtLink>
                </div>
              </Transition>
            </ClientOnly>
          </div>
        </el-carousel-item>
      </el-carousel>
    </ClientOnly>
  </div>
</template>
