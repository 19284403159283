<script lang="ts" setup>
import { Switch } from "@element-plus/icons-vue";
import { useWindowSize } from "@vueuse/core";
import {
  numberExponentToLarge,
  formatNumberWithSpaces,
  fiatConvert,
  cryptoConvert,
} from "@/shared/utils";
const { t } = useI18n();
const router = useRouter();
const { width } = useWindowSize();
const RatesStore = useRatesStore();
const { rates } = storeToRefs(RatesStore);
const CurrencyStore = useCurrencyStore();
const {
  cryptoCurrencies,
  fiatCurrencies,
  loading: curLoading,
} = storeToRefs(CurrencyStore);
const { getSympolById } = CurrencyStore;
const sendSelected = ref("USDT");
const sendAmount = ref("");
const recieveSelected = ref("RUB");
const recieveAmount = ref("");

const loading = ref(false);
const visible = ref(false);

const sendGroup = computed(() => {
  return { crypto: cryptoCurrencies.value };
});

const recieveGroup = computed(() => {
  return { fiat: fiatCurrencies.value };
});

const desctopWidth = computed(() => {
  const myWidth = width.value === Infinity ? 0 : width.value;
  return myWidth > 450;
});

const triggerChange = (way: string) => {
  visible.value = false;
  loading.value = false;
  if (way === "send") {
    if (sendAmount.value === "") {
      recieveAmount.value = "";
      return;
    }
    recieveAmount.value = (
      rates.value[sendSelected.value]?.[recieveSelected.value] *
      +sendAmount.value
    ).toFixed(2);
  } else {
    if (recieveAmount.value === "") {
      sendAmount.value = "";
      return;
    }
    sendAmount.value = numberExponentToLarge(
      +recieveAmount.value /
        rates.value[sendSelected.value]?.[recieveSelected.value]
    ).slice(0, 10);
  }
};

const sellOffers = ref<any[]>([]);
const buyOffers = ref<any[]>([]);
const localePath = useLocalePath();

const findTrigger = async () => {
  router.push({
    path: localePath(`/trade/${offerType.value}`),
    query: {
      send: sendSelected.value,
      recieve: recieveSelected.value,
      amount: recieveAmount.value,
    },
  });
  // visible.value = false;
  // loading.value = true;
  // sellOffers.value = await getOffers({
  //   send: sendSelected.value,
  //   recieve: recieveSelected.value,
  //   amount: recieveAmount.value,
  //   limit: 2,
  //   offset: 0,
  //   type: "sell",
  // });
  // buyOffers.value = await getOffers({
  //   send: sendSelected.value,
  //   recieve: recieveSelected.value,
  //   amount: recieveAmount.value,
  //   limit: 2,
  //   offset: 0,
  //   type: "buy",
  // });
  // loading.value = false;
  // visible.value = true;
};
const offerType = ref("buy");
</script>

<template>
  <el-card v-loading="curLoading">
    <ClientOnly>
      <template #fallback>
        <el-skeleton :rows="3" animated />
      </template>
      <el-row :gutter="50" class="mb-5">
        <el-col :span="12">
          {{ t("c-widget-Search-crypto") }}
          <div class="pb-3 switch-cont">
            <el-icon class="switch-button">
              <Switch class="color-warning" />
            </el-icon>
            <el-select
              class="w-full"
              v-model="sendSelected"
              filterable
              placeholder="select type..."
              @change="triggerChange('send')"
            >
              <ClientOnly>
                <el-option-group
                  v-for="(group, key) in sendGroup"
                  :key="key"
                  :label="key"
                >
                  <el-option
                    v-for="item in group"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-option-group>
              </ClientOnly>
            </el-select>
          </div>
          <div class="switch-cont">
            <i class="eqiality">~</i>
            <el-input
              v-model="sendAmount"
              :placeholder="`${t('c-widget-Search-amount')} ${
                offerType === 'sell'
                  ? t('c-widget-Search-send')
                  : t('c-widget-Search-recieve')
              }`"
              @input="triggerChange('send')"
            >
              <template #append v-if="desctopWidth && sendSelected">
                {{ getSympolById(sendSelected) }}
              </template>
            </el-input>
          </div>
        </el-col>
        <el-col :span="12">
          {{ t("c-widget-Search-fiat") }}
          <div class="pb-3">
            <el-select
              class="w-full"
              v-model="recieveSelected"
              @change="triggerChange('recieve')"
              filterable
              placeholder="select type..."
            >
              <ClientOnly>
                <el-option-group
                  v-for="(group, key) in recieveGroup"
                  :key="key"
                  :label="key"
                >
                  <el-option
                    v-for="item in group"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-option-group>
              </ClientOnly>
            </el-select>
          </div>
          <div>
            <el-input
              @scroll.prevent="() => {}"
              v-model="recieveAmount"
              :placeholder="`${t('c-widget-Search-amount')} ${
                offerType === 'buy'
                  ? t('c-widget-Search-send')
                  : t('c-widget-Search-recieve')
              }`"
              @input="triggerChange('recieve')"
            >
              <template #append v-if="desctopWidth && recieveSelected">
                {{ getSympolById(recieveSelected) }}
              </template>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="-1">
          <el-radio-group
            v-model="offerType"
            size="default"
            class="order-type-group mb-2"
          >
            <el-radio
              class="mb-1"
              :label="`${t('c-widget-Search-buy')} ${
                offerType === 'buy' ? t('c-widget-Search-crypto') : ''
              }`"
              border
              value="buy"
            />
            <el-radio
              class="mb-1"
              :label="`${t('c-widget-Search-sell')} ${
                offerType === 'sell' ? t('c-widget-Search-crypto') : ''
              }`"
              border
              value="sell"
            />
          </el-radio-group>
        </el-col>
        <el-col :span="-1">
          <el-popover
            placement="bottom"
            :width="300"
            trigger="click"
            :visible="visible || loading"
          >
            <template #reference>
              <el-button round  :loading="loading" type="primary" @click="findTrigger">
                {{ t("c-widget-Search-find") }}
              </el-button>
            </template>
            <div class="search-offers">
              <template v-if="loading">
                <el-skeleton :rows="3" animated />
              </template>
              <template v-else>
                <template v-if="sellOffers.length">
                  <el-divider style="margin-top: 5px" class="mt-0">
                    {{ t("c-widget-Search-selling-offers") }}
                  </el-divider>
                  <div
                    v-for="item in sellOffers"
                    :key="item.offerId"
                    class="mb-1 offer-row"
                  >
                    <el-row class="mt-0 mb-0" :gutter="10">
                      <el-col :span="-1">
                        {{ item.offerCreator.nickname }}
                      </el-col>
                      <el-col :span="-1">
                        <el-tag type="danger">{{
                          t("c-widget-Search-selling")
                        }}</el-tag>
                      </el-col>
                      <el-col :span="-1"> </el-col>
                    </el-row>
                    <el-row class="mt-0 mb-0" :gutter="10">
                      <el-col :span="-1"
                        >{{ t("c-widget-Search-price") }}:
                      </el-col>
                      <el-col :span="-1">
                        <span class="color-warning">
                          {{
                            formatNumberWithSpaces(
                              fiatConvert(item.exchangeRate, true)
                            )
                          }}
                          {{ item.currencyOut }}
                        </span>
                      </el-col>
                    </el-row>
                    <el-row class="mt-0 mb-0" :gutter="10">
                      <el-col :span="-1"
                        >{{ t("c-widget-Search-available") }}:
                      </el-col>
                      <el-col :span="-1">
                        <span class="color-primary">
                          {{
                            cryptoConvert(
                              item.offerAmount,
                              item.currencyIn,
                              true
                            )
                          }}
                          {{ item.currencyIn }}
                        </span>
                      </el-col>
                    </el-row>
                  </div>
                </template>
                <template v-if="buyOffers.length">
                  <el-divider>
                    {{ t("c-widget-Search-buying-offers") }}
                  </el-divider>
                  <div
                    v-for="item in buyOffers"
                    :key="item.offerId"
                    class="mb-1 offer-row"
                  >
                    <el-row class="mt-0 mb-0" :gutter="10">
                      <el-col :span="-1">
                        {{ item.offerCreator.nickname }}
                      </el-col>
                      <el-col :span="-1">
                        <el-tag type="success">{{
                          t("c-widget-Search-buying")
                        }}</el-tag>
                      </el-col>
                      <el-col :span="-1"> </el-col>
                    </el-row>
                    <el-row class="mt-0 mb-0" :gutter="10">
                      <el-col :span="-1"
                        >{{ t("c-widget-Search-price") }}:
                      </el-col>
                      <el-col :span="-1">
                        <span class="color-warning">
                          {{
                            formatNumberWithSpaces(
                              fiatConvert(item.exchangeRate, true)
                            )
                          }}
                          {{ item.currencyOut }}
                        </span>
                      </el-col>
                    </el-row>
                    <el-row class="mt-0 mb-0" :gutter="10">
                      <el-col :span="-1"
                        >{{ t("c-widget-Search-available") }}:
                      </el-col>
                      <el-col :span="-1">
                        <span class="color-primary">
                          {{
                            cryptoConvert(
                              item.offerAmount,
                              item.currencyIn,
                              true
                            )
                          }}
                          {{ item.currencyIn }}
                        </span>
                      </el-col>
                    </el-row>
                  </div>
                </template>
                <el-empty
                  v-if="!buyOffers.length && !sellOffers.length"
                  :description="t('c-widget-Search-nothing-found')"
                />
                <el-row class="mt-4">
                  <div class="flex-grow"></div>
                  <el-button round  size="small" @click="visible = false">
                    {{ t("c-widget-Search-close") }}
                  </el-button>
                  <el-button round 
                    size="small"
                    type="primary"
                    plain
                    @click="
                      router.push({
                        path: localePath('/trade/buy'),
                        query: {
                          send: sendSelected,
                          recieve: recieveSelected,
                          amount: recieveAmount,
                        },
                      })
                    "
                  >
                    {{ t("c-widget-Search-show-more") }}
                  </el-button>
                </el-row>
              </template>
            </div>
          </el-popover>
        </el-col>
      </el-row>
    </ClientOnly>
  </el-card>
</template>

<style lang="scss" scoped>
.order-type-group {
  vertical-align: middle;
  .el-radio {
    margin-right: 0.5rem;
  }
}
.order-type-sell {
  .el-radio-button__original-radio:checked + .el-radio-button__inner {
    background: var(--el-color-danger);
    border-color: var(--el-border-color);
  }
}
.order-type-buy {
  .el-radio-button__original-radio:checked + .el-radio-button__inner {
    background: var(--el-color-success);
    border-color: var(--el-border-color);
  }
}
.offer-row {
  margin: -3px;
  padding: 3px;
  border-radius: 3px;
  &:hover {
    box-shadow: inset 0 0 0 100px var(--el-fill-color);
  }
}
.radio-group {
  display: flex;
  .label {
    flex: 1;
    :deep(.el-radio-button__inner) {
      width: 100%;
    }
  }
}

.switch-cont {
  position: relative;
  .switch-button,
  .eqiality {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 100%;
    margin: 0 8px;
    padding: 8px;
    box-sizing: border-box;
    line-height: 1;
  }
}
.want-to {
  font-size: 1rem;
  @media (max-width: 559px) {
    font-size: 0.9rem;
  }
}
:deep(.el-card__body) {
  @media (max-width: 559px) {
    padding: 0.5rem;
  }
}
</style>
