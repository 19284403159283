<script setup lang="ts">
const { t, locale } = useI18n();
import { fetchPosts } from "@/api";
import type { BlogItemList } from "@/shared/types";
import { ElMessage } from "element-plus";
const loading = ref(true);
const posts = ref<BlogItemList[]>([]);
const props = defineProps<{
  limit?: number;
  maxPostHeight?: string;
  titleOnly?: boolean;
  imageWidth?: string;
}>();
const postsLimit = computed(() => props.limit || 2);

const searchPosts = async (passedParams?: Record<string, string | number>) => {
  loading.value = true;
  try {
    const model: Record<string, string | number> = {
      limit: postsLimit.value,
      offset: passedParams?.offset || 0,
      locale: locale.value,
    };
    if (model.offset === 0) {
      posts.value = [];
    }
    if (activeTab.value === "archive") {
      model.status = "hidden";
    }
    const res = await fetchPosts(model);
    posts.value = [...posts.value, ...res];
  } catch (error: any) {
    ElMessage.error(
      error?.data?.data?.message || t("p-blog-index-failed-to-fetch-posts")
    );
  }
  loading.value = false;
};
const activeTab = ref<"active" | "archive">("active");
onMounted(async () => {
  searchPosts();
});
</script>

<template>
  <div>
    <el-skeleton v-if="loading" animated :rows="6"></el-skeleton>
    <BlogItem
      v-else
      v-for="post in posts"
      :key="post.idCode"
      v-bind="post"
      :max-height="(props.maxPostHeight as string)"
      :title-only="props.titleOnly"
      :image-width="props.imageWidth"
    />
  </div>
</template>
