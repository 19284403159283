import { request } from "@/api";
import type { CartDataItem } from "@/shared/types";

interface FetchParams {
  fsym: string;
  tsym: string;
  interval: string;
  limit: number;
}
export const fetchHistoryCharts = async (params: FetchParams) => {
  return request<CartDataItem[]>("/charts/history", {
    params,
  });
};
