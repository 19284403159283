<script setup lang="ts">
const { width } = useWindowSize();
const { t } = useI18n();
const handleClick = (command: string) => {
  period.value = command;
};
const getPeriodTitle = (periodPassed?: string) => {
  switch (periodPassed || period.value) {
    case "day":
      return t("c-home-historywidgets-24hours");
    case "week":
      return t("c-home-historywidgets-1week");
    case "month":
      return t("c-home-historywidgets-1month");
  }
};
const periodInterval = computed(() => {
  switch (period.value) {
    case "day":
      return "1h";
    case "week":
      return "1h";
    case "month":
      return "1d";
    default:
      return "1h";
  }
});
const periodLimit = computed(() => {
  switch (period.value) {
    case "day":
      return 24;
    case "week":
      return 7 * 24;
    case "month":
      return 31;
    default:
      return 24;
  }
});
const periods = ["day", "week", "month"];
const period = ref("day");
</script>

<template>
  <div>
    <h3>
      {{ t("c-home-historywidgets-usdRatesHistory") }} {{ getPeriodTitle() }}
      <ClientOnly>
        <el-dropdown trigger="click" @command="handleClick">
          <el-button type="primary" size="small" link round>{{
            t("c-home-historywidgets-change")
          }}</el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="period in periods"
                :key="period"
                :command="period"
                class="curr-item"
              >
                <span>{{ getPeriodTitle(period) }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </ClientOnly>
    </h3>
    <ClientOnly>
      <template #fallback>
        <el-skeleton :rows="5" animated />
      </template>
      <el-row :gutter="width > 600 ? 10 : 4">
        <el-col :span="8" class="mb-2">
          <WidgetHistoryChart
            fsym="BTC"
            tsym="USD"
            :interval="periodInterval"
            :limit="periodLimit"
          />
        </el-col>
        <el-col :span="8" class="mb-2">
          <WidgetHistoryChart
            fsym="ETH"
            tsym="USD"
            :interval="periodInterval"
            :limit="periodLimit"
          />
        </el-col>
        <el-col :span="8" class="mb-2">
          <WidgetHistoryChart
            fsym="XMR"
            tsym="USD"
            :interval="periodInterval"
            :limit="periodLimit"
          />
        </el-col>
      </el-row>
    </ClientOnly>
  </div>
</template>

<style scoped lang="scss">
:deep(.curr-item) {
  min-width: 80px;
  align-items: center;
  justify-content: center;
}
</style>
