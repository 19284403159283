<script setup lang="ts">
const props = defineProps<{ to: string }>();
</script>

<template>
  <Nuxt-link class="rd banner-item p-2" :to="props.to"> <slot /> </Nuxt-link>
</template>

<style scoped lang="scss">
.banner-item {
  background: var(--el-text-color-primary);
  border: 1ps solid var(--el-border-color-light);
  box-shadow: var(--el-box-shadow-light);
  color: var(--el-bg-color);
  transition: all 0.3s;
  cursor: pointer;
  text-decoration: none;
  display: block;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    transform: translateY(-3px);
  }
}
</style>
