<script setup lang="ts">
const { t } = useI18n();
import { Coin } from "@element-plus/icons-vue";
</script>

<template>
  <el-row :gutter="20">
    <el-col :span="8" class="banner-col">
      <HomeMiniBanner to="/about">
        <el-row justify="space-between">
          <el-col :span="-1">
            <h4>{{ t("c-home-banners-getToKnowUs") }}</h4>
          </el-col>
          <el-col :sm="6" :xs="8">
            <Logo status="loading" />
          </el-col>
        </el-row>
      </HomeMiniBanner>
    </el-col>
    <el-col :span="8" class="banner-col">
      <HomeMiniBanner to="/blog/what-to-do-if-you-receive-dirty-cryptocurrency">
        <el-row class="back-img-2 back-img">
          <el-col :span="18">
            {{ t("c-home-banners-dirtyCryptocurrency") }}
          </el-col>
        </el-row>
      </HomeMiniBanner>
    </el-col>
    <el-col :span="8" class="banner-col">
      <HomeMiniBanner to="/beginners-guide">
        <div>
          <h4>{{ t("c-home-banners-beginnersGuide") }}</h4>
          <el-icon class="vertical-middle coin-icon"><Coin /></el-icon>
          <el-icon class="vertical-middle coin-icon"><Coin /></el-icon>
          <el-icon class="vertical-middle coin-icon"><Coin /></el-icon>
        </div>
      </HomeMiniBanner>
    </el-col>
  </el-row>
</template>

<style scoped lang="scss">
.banner-col {
  display: flex;
  align-items: stretch;
  * {
    @media (max-width: 1100px) {
      font-size: 90%;

      :deep(.el-button) {
        font-size: 60%;
      }
    }
  }
}
.back-img-1 {
  background: 100% 100% url("/img/logo/logo.png") no-repeat;
}
.back-img-2 {
  background: 100% 100% url("/img/tos.png") no-repeat;
}
.back-img {
  background-size: contain;
  transition: background-size 0.3s;
  overflow: hidden;
  @media (max-width: 1170px) {
    background-size: 50px;
  }
  @media (max-width: 950px) {
    background-size: 30px;
  }
  @media (max-width: 700px) {
    background-size: 0px;
  }
}
.coin-icon {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>
