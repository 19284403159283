<script setup lang="ts">
const UseInitStore = useInitStore();
const { initData } = storeToRefs(UseInitStore);
</script>

<template>
  <ClientOnly>
    <template #fallback>
      <el-skeleton animated :rows="2" />
    </template>
    <el-row :gutter="20" class="social-logos">
      <!-- <el-col :span="4" v-if="initData?.country !== 'RU'">
      <a href="" target="_blank">
      <div class="social-logo">
        <SocialNetworksFacebook />
      </div>
      Facebook
      </a>
    </el-col> -->
      <el-col :span="5" v-if="initData?.country !== 'RU'">
        <a href="https://www.instagram.com/solid.swap" target="_blank">
          <div class="social-logo">
            <SocialNetworksInstagram />
          </div>
          <span class="name">Instagram</span>
        </a>
      </el-col>
      <el-col :span="5">
        <a href="https://www.linkedin.com/company/solid-swap" target="_blank">
          <div class="social-logo">
            <SocialNetworksLinkedIn />
          </div>
          <span class="name">LinkedIn</span>
        </a>
      </el-col>
      <el-col :span="5">
        <a href="https://x.com/SolidSwap" target="_blank">
          <div class="social-logo">
            <SocialNetworksX />
          </div>
          <span class="name">X.com</span>
        </a>
      </el-col>
      <el-col :span="5">
        <a href="https://t.me/solidswap_ru" target="_blank">
          <div class="social-logo">
            <SocialNetworksTelegram />
          </div>
          <span class="name">Telegram</span>
        </a>
      </el-col>
      <el-col :span="4">
        <a href="https://vk.com/SolidSwap" target="_blank">
          <div class="social-logo">
            <SocialNetworksVK />
          </div>
          <span class="name">VK</span>
        </a>
      </el-col>
    </el-row>
  </ClientOnly>
</template>

<style scoped lang="scss">
.social-logos {
  white-space: nowrap;
  text-align: center;
  a {
    text-decoration: none;
    color: var(--el-text-color-primary);
  }
  @media (max-width: 580px) {
    .name {
      display: none;
    }
  }
}
.social-logo {
  width: 50px;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  @media (max-width: 680px) {
    width: 40px;
  }
}
</style>
